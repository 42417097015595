@import 'src/styles/scss/mixins';
@import 'src/styles/scss/variables';

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  margin-bottom: 128px;

  hr {
    margin: 130px 112px 90px 112px;
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid #494c62;
    @include big-phone {
      margin: 0 16.5px;
      margin-top: 55px;
    }
  }

  @include phone {
    width: 100%;
    flex-direction: column;
    gap: 100px;
    margin-top: 57px;
    margin-bottom: 0px;
  }
  .content {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 112%;
    text-align: center;
    color: $text-color;
    margin-bottom: 99px;
    padding-top: 67px;
    @include phone {
      padding-top: 25px;
      margin-bottom: 42px;
    }
    p {
      margin: 0;
    }
    @include headings {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 112%;
      text-align: center;
      color: $text-color;
      margin: 0;
      @include phone {
        font-size: 30px;
      }
    }
  }
}

.listWrapper {
  display: flex;
  gap: 3.1%;
  margin: 50px auto 9.653vw;
  justify-content: space-between;

  @include phone {
    flex-direction: column;
    // gap: 3.472vw;
    margin-top: 0;
    gap: 42px;
  }

  @include big-phone {
    padding: 0;
    // gap: 28px;
  }
}

.bottomLine {
  border-bottom: 1px solid #494c62;
  width: 85vw;
  margin: 0 auto;

  margin-bottom: 17.361vw;

  @include phone {
    margin-bottom: 95px;
  }
}
