@import 'src/styles/scss/mixins';

.nav {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 22px;
  @include laptop {
    margin-right: 10px;
  }
  @include maxTablet {
    width: 100%;
    flex-grow: unset;
    justify-content: flex-start;
    max-width: 100%;
    margin-right: 0px;
    padding: 142px 0 0;
    overflow: hidden;
    :global {
      // div[class^='NavContainer__container'] {
      .open {
        display: flex;
      }
      // }
    }
  }
}
