@import 'src/styles/scss/mixins';

.deviceBlockWrapper {
  &Block {
    display: flex;
    justify-content: space-between;
    padding: 2.639vw 5.764vw 7.917vw 7.778vw;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    max-width: 1440px;
    margin: 0 auto;

    &Content {
      .linkWithIcon {
        display: flex;
        align-items: center;
        margin-top: 24px;
        .link {
          all: unset;
          color: white;
          font-weight: 700;
          font-size: 18px;
          line-height: 112.5%;
          margin-right: 25.7px;
        }
      }

      &Content {
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        li {
          margin-bottom: 53px;
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: 700;
          font-size: 34px;
          line-height: 112%;
          color: $text-color;

          @include tablet {
            font-size: 24px;
            margin-bottom: 14px;
          }
        }

        p,
        li {
          color: $secondary-text-color;
          font-size: 22px;
          font-weight: 400;
          line-height: 140%;
          @include tablet {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.right {
  flex-direction: row;

  @include laptop {
    flex-direction: column;
  }

  .deviceBlockWrapperBlockContent {
    // margin-right: 25.3vw;
    width: 48.7%;
    display: flex;
    flex-direction: column;
    padding-left: 17%;
    @include laptop {
      padding-left: 0;
    }
    .deviceBlockWrapperBlockContentContent {
      min-width: 479px;
      p,
      li {
        max-width: 340px;
      }
      @include phone {
        min-width: unset;
      }
    }
    @include laptop {
      margin-right: 0;
    }
    @include phone {
      width: 100%;
      max-width: 310px;
    }
  }
  .imageWithDots {
    position: relative;
    min-height: 556.48px;

    @include laptop {
      min-height: 250px;
    }

    .image {
      position: absolute;
      top: -26.4%;
      left: -100%;
    }

    .dots {
      margin-top: 20px;
    }
  }

  .imageWithoutDots {
    margin: 35px auto 48px auto;
    @include laptop {
      margin: 35px auto 20px auto;
    }
  }
}

.top {
  flex-direction: column;
}

.bottom {
  flex-direction: column-reverse;
}

div.left {
  flex-direction: row-reverse;
  padding-top: 104px;
  @include laptop {
    padding-top: 0;
    flex-direction: column;
  }

  @include phone {
    padding-top: 0;
  }

  .deviceBlockWrapperBlockContent {
    width: 48.7%;
    .deviceBlockWrapperBlockContentContent {
      min-width: 479px;
      p,
      li {
        max-width: 340px;
      }
      @include phone {
        min-width: unset;
      }
    }

    @include laptop {
      margin-left: 0;
    }

    @include phone {
      width: 100%;
      max-width: 310px;
    }
  }
  .imageWithDots {
    position: relative;
    min-height: 556.48px;

    @include laptop {
      min-height: 250px;
    }

    .image {
      position: absolute;
      // top: -25.4%;
      // left: 48%;
      top: -22.9%;
      left: 60%;
    }

    .dots {
      margin-top: 20px;
      margin-left: 12px;
    }
  }

  .imageWithoutDots {
    margin: 35px auto 48px auto;
    @include laptop {
      margin: 35px auto 20px auto;
    }
  }
}
