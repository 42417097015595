@import "src/styles/scss/mixins";

.cookiesBanner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 19px 116px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 214748300311;
  @include maxTablet {
    padding: 10px 50px;
  }
  @include minTablet {
    padding: 10px 10px;
  }
  .content {
    font-weight: 400;
    font-size: 14px;
    color: white;
    max-width: 62.500vw;

    @include phone {
      max-width: 45.500vw;
    }
    a {
      all: unset;
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
    }
    p {
      @include phone {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .show {
    p {
      @include phone {
        overflow: initial;
        text-overflow: initial;
        white-space: initial;
      }
    }
    button {
      @include phone {
        min-width: 139px;
      }
    }
  }
  button {
    margin-left: 31px;
    @include phone {
      margin-left: 16px;
      font-size: 14px;
      min-width: 100px;
    }
  }
}
