@import './normalize.scss';
@import './variables';
@import './mixins';
@import './font.scss';
@import './components/section';

*,
*::after,
*::before {
  box-sizing: border-box;
  font-family: 'Proxima Nova';
}
*:focus {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
html {
  font-size: 16px;
  padding: 0;
  margin: 0;
  min-height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: 'Proxima';
  padding: 0;
  margin: 0;
  height: 100%;
  background: linear-gradient(166.89deg, #33364c 3.33%, #222434 28.55%, #25283a 53.5%, #0c0e1b 89.92%);
  // background: -webkit-gradient(linear, left top, left bottom, from(rgb(36, 23, 23)), to(#aee50b)) fixed;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.container {
  padding: 0 112px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  @include maxTablet {
    padding: 0 60px;
  }
  @include tablet {
    padding: 0 16px;
  }
}
.ReactModal__Overlay {
  z-index: 11;
  button {
    background: none;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    svg {
      path {
        transition: stroke 0.3s ease-in-out;
      }
    }
    &:hover {
      svg path {
        stroke: $secondary-text-color;
      }
    }
  }
}
.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: linear !important;
}
.popup-form {
  display: none;
}
