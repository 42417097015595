@import 'src/styles/scss/mixins';

.titleSection {
  background-size: cover;
  background-repeat: no-repeat;
  :global {
    .container {
      padding-top: 133px;
      padding-bottom: 133px;
    }
  }
  @include tablet {
    :global {
      .container {
        padding-top: 60px;
        padding-bottom: 60px;
      }
    }
  }

  &__container {
  }
  &__title {
    padding: 0 0 7px;
    margin: 0;
    letter-spacing: 0.6px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 112%;
    color: $text-color;
    @include maxTablet {
      font-size: 40px;
    }
    // @include minTablet {
    //   font-size: 20px;
    // }
  }
}
