@import 'src/styles/scss/mixins';

.quotation {
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.472vw 0;
  margin-bottom: 98px;

  @include tablet {
    padding: 0 4.267vw 2vw 4.267vw;
  };

  &Content {
    max-width: 518px;

    @include tablet {
      max-width: 100%;
    }

    p, ul, ol {
      font-size: 22px;
      color: $secondary-text-color;


      @include tablet {
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
      }
    }
  }
}

.left {
  flex-direction: row;
  align-items: center;
  @include phone {
    flex-direction: column;
    align-items: start;
  }

  .quotationImage {
    margin-right: 3.194vw;

    @include tablet {
      width: 18vw;
    }

    @include big-phone {
      width: 10vw;
    }

    @include phone {
      margin-bottom: 20px;
      width: 60px;
    }
  }
}

.right {
  flex-direction: row-reverse;
  align-items: center;
  @include phone {
    flex-direction: column;
    align-items: start;
  }

  .quotationImage {
    margin-left: 3.194vw;
    @include tablet {
      width: 18vw;
    }

    @include big-phone {
      width: 10vw;
    }

    @include phone {
      margin-bottom: 20px;
      width: 60px;
    }
  }
}

.top {
  flex-direction: column;
}
.bottom {
  flex-direction: column-reverse;
}
