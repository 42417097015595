@import 'src/styles/scss/mixins';

.grid {
  // padding-top: 122px;
  padding-bottom: 137px;
  @include tablet {
    padding-top: 79px;
    padding-bottom: 73px;
  }
  &Title {
    text-align: center;
    margin-bottom: 81px;
    @include phone {
      margin-bottom: 39px;
    }
    @include headings {
      font-size: 50px;
      font-weight: 700;
      line-height: 112%;
      color: $text-color;
      margin-bottom: 41px;
      @include phone {
        font-size: 30px;
        margin-bottom: 20px;
      }
    }
    p,
    li {
      font-size: 22px;
      color: $secondary-text-color;
      margin: 0 auto;
      max-width: 652px;
      line-height: 1.4;
      @include phone {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
      }
    }
  }
  &Items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // display: grid;
    // grid-template-columns: repeat(4 , 1fr);
    // grid-column-gap: 6.528vw;
    // grid-row-gap : 4.931vw;
    margin-bottom: 106px;
    gap: 80px 5%;
    padding: 0 25px;
    @include tablet {
      padding: 0 15px;
      gap: 38px 5%;
    }
    @include phone {
      margin-bottom: 35px;
    }
    &Item {
      width: 216px;
      @include tablet {
        width: 140px;
      }
      &Image {
        display: flex;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-size: contain;
        padding-bottom: 13px;
        margin: 0 auto;
        // width: 100%;
        // height: 100%;
        width: 100%;
        height: 216px;
        @include tablet {
          height: 140px;
        }
        img {
          max-width: 100%;
        }
      }
      &Title {
        margin-top: 41px;
        font-size: 22px;
        color: $text-color;
        text-align: center;
        font-weight: 400;
        @include tablet {
          font-weight: 400;
          font-size: 18px;
          line-height: 140%;
          margin-top: 16px;
        }
      }
    }
  }
  button {
    margin: 0 auto;
    min-width: 216px;
    @include phone {
      min-width: 184px;
    }
  }
}
