@import 'src/styles/scss/mixins';

.syncBanner {
  padding: 67px 0 0px;

  @include tablet {
    padding: 32px 0 0;
  }
  hr {
    @include tablet {
      margin-bottom: 36px;
      margin-top: 28px;
    }
  }
}
