@import 'src/styles/scss/mixins';

$titleFz: 24px;
$itemFz: 16px;

.mobileSwiper {
  padding-left: 16px;
  padding-right: 16px;
  div > :global(.swiper-pagination-bullet) {
    background-color: #d416b9;
    margin-right: 19px !important;
    margin-left: 19px !important;

    &:last-child {
      margin-right: 0px;
    }
  }
  margin-bottom: 50px;
  :global {
    .swiper-wrapper {
      padding-left: 10px;
    }
    .swiper-pagination-bullets {
      bottom: -2px;
    }
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
    }
  }
}
.slideBackground {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
}
.blockUlImageWithContent {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 31px 6px 50px 0px;
  // padding: 38px 43px 50px 18px;

  .image {
    z-index: 2;
    margin-right: auto;
    max-height: 183px;
    margin-left: 35px;
    margin-bottom: 38px;
    max-width: 254px;
    height: auto;
  }

  .content {
    z-index: 2;
    max-width: 78.4vw;
    @include phone {
      width: 100%;
      max-width: 434px;
    }
    @include headings {
      color: $text-color;
      font-size: $titleFz;
      margin-bottom: 1.7vw;
      margin-top: 0;
      @include tablet {
        font-size: 24px;
        max-width: 294px;
      }
    }

    p,
    li {
      font-size: $itemFz;
      color: $secondary-text-color;
      margin-bottom: 1.2vw;
      line-height: 140%;

      @include tablet {
        font-size: 18px;
        max-width: 254px;
      }

      @include medium-phone {
        font-size: 16px;
      }
    }
  }
}

.title {
  max-width: 315px;
  margin-left: 18px;
  margin-right: 45px;
  color: $text-color;
  font-size: $titleFz;

  @include tablet {
    font-size: 20px;
  }
}
