@import 'src/styles/scss/mixins';

.header {
  display: flex;
  flex-direction: column;

  position: relative;
  @include maxTablet {
    background-color: none;
  }
  &_open {
    box-shadow: 0px 11px 22px 0px rgba(255, 255, 255, 0.05);
    .header__inner {
      // min-height: 100vh;
      @include maxTablet {
        height: 100%;
      }
    }
  }
  &__inner {
    height: 147px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include maxTablet {
      height: 92px;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: $macbook-thirteen;
    margin: 0 auto;
    padding-top: 27px !important;
    height: 100%;
    background-color: $background-color;
    @include maxTablet {
      overflow: hidden;
      padding-top: 0 !important;
    }
    @include phone {
      background-color: unset;
    }
    .header__button {
      // width: 184px;
      width: 163px;
      max-width: 163px;
      min-width: 163px;
      white-space: nowrap;
      margin-right: 0;
      margin-left: auto;
      @include maxTablet {
        margin: 127px 0 66px 7vw;
        height: 60px;
        letter-spacing: 1px;
        // background-color: none;
      }
    }
  }
  :global {
    .container {
      @include maxTablet {
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        max-width: unset;
        padding: 0;
        height: 100%;
      }
    }
    .logo {
      min-width: 98px;
      max-width: 146px;
      img {
        display: block;
        max-width: 100%;
      }

      @include maxTablet {
        position: absolute;
        left: 60px;
        top: 21px;
        width: 86px;
        min-width: unset;
      }
      @include tablet {
        left: 16px;
      }
    }
  }
}
