@import 'src/styles/scss/mixins';

$titleFz: 34px;

.blockUlWrapper {
  .blockUl {
    background-size: cover;
    background-position: left bottom;
    background-repeat: no-repeat;
    max-width: 1440px;
    margin: auto;
    padding: 89px 0px 130px 112px;

    @include laptop {
      padding-left: 50px;
    }

    display: flex;

    &Navigation {
      display: flex;
      flex-direction: column;
      z-index: 2;
      margin-top: 58px;
      width: 32%;
      @include laptop {
        margin-top: 0;
      }

      &Title {
        color: $text-color;
        font-size: $titleFz;
        max-width: 386px;
        margin-top: 0;

        @include laptop {
          font-size: 24px;
        }
      }

      &List {
        list-style: none;
        margin-top: 72px;
        padding-left: 0;
        &Wrapper {
          display: flex;
          margin-bottom: 56px;
          margin-left: 0px;

          &:last-child {
            margin-bottom: 0;
          }

          &Point {
            margin-top: 15px;
            height: 13px;
            min-width: 13px;
            border-radius: 50%;
            background-color: $light-gray;
          }

          &Item {
            color: $secondary-text-color;
            font-size: 34px;
            font-family: 'Proxima Nova';
            line-height: 140%;
            font-weight: 700;
            margin-left: 42px;
            cursor: pointer;
            max-width: 408px;
            &:hover {
              transform: scale(1.05);
            }
            @include laptop {
              font-size: 16px;
            }
          }
        }
        .active {
          .blockUlNavigationListWrapperPoint {
            background-color: $secondary-color;
          }

          .blockUlNavigationListWrapperItem {
            color: $text-color;
          }
        }
      }
    }
    &ImageWithContent {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-left: 121px;
      width: 59%;

      @include laptop {
        margin-left: 90px;
      }

      @include maxTablet {
        margin-left: 25px;
      }
      > button {
        margin-left: 10%;
        padding-left: 2px;
        margin-top: 30px;
      }

      .slideBackground {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 31.7%;
        // top: -5.9%;
        // left: -15%;

        @include laptop {
          max-width: 400px;
        }

        @include maxTablet {
          max-width: 300px;
        }

        @include laptop {
          max-width: 200px;
        }
      }

      .image {
        z-index: 2;
        width: 100%;
        margin-bottom: 0;
        // max-width: 647px;
        max-width: 519px;
        height: auto;
        margin-left: 10%;
        margin-top: 5.2%;
        @include laptop {
          margin-right: 10%;
          width: auto;
        }
        // @include laptop {
        //   max-width: 400px;
        // }

        // @include maxTablet {
        //   max-width: 300px;
        // }

        // @include laptop {
        //   max-width: 200px;
        // }
      }

      .content {
        z-index: 2;
        // width: 100%;
        max-width: 525px;
        margin-top: 32px;
        margin-left: 10%;
        @include laptop {
          max-width: 100%;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $text-color;
          font-weight: 700;
          font-size: 34px;
          margin-bottom: 20px;
          margin-top: 0;

          @include maxTablet {
            font-size: 28px;
          }

          @include laptop {
            font-size: 24px;
          }
        }

        p,
        li {
          //font-size: $itemFz;
          color: $secondary-text-color;
          margin-bottom: 17px;
          font-weight: 400;
          font-size: 22px;
          line-height: 140%;
          margin: 0 0 0;

          @include maxTablet {
            font-size: 20px;
          }

          @include laptop {
            font-size: 18px;
          }

          @include medium-phone {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.mobileSliderWrapper {
  margin-top: 119px;

  @include laptop {
    margin-top: 53px;
  }
}
