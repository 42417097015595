@import 'src/styles/scss/mixins';

.solutionsLeft {
  width: 100%;
  margin-left: 9vw;

  @include tablet {
    margin-left: 0;
  }
}

.solutionsRight {
  width: 100%;
  margin-right: 9vw;

  @include tablet {
    margin-right: 0;
  }
}

.solutionsWrapper {
  max-width: 1440px;
  margin: 0 auto;
  @include tablet {
    padding: 0 5.764vw 68px 7.778vw;
  }

  @include phone {
    padding: 0 43px 0 18px;
  }
}

.solutionsContent {
  @include phone {
    margin-right: 0;
  }
}
.solutionsWrapper.solutionsContent {
  @include phone {
    :global(.container) {
      padding: 0;
    }
  }
}
