@import 'src/styles/scss/mixins';
.burger {
  position: absolute;
  right: 77px;
  top: 31px;
  display: flex;
  align-items: center;
  outline: none;
  height: 30px;
  width: 30px;
  border: 0px;
  padding: 0px;
  background: transparent;
  transition: all 250ms ease-out;
  cursor: pointer;
  @include tablet {
    right: 24px;
  }
}

.burger:before,
.burger:after {
  content: '';

  height: 2px;
  position: absolute;
  background: $text-color;
  transition: all 250ms ease-out;
  will-change: transform;
}

.burger:before {
  width: 29px;
  transform: translateY(-3px);
}

.burger:after {
  width: 20px;
  transform: translateY(3px);
}

// line transition
.burger_active:before,
.burger_active:after {
  width: 25px;
}
.burger_active:before {
  transform: translateY(0) rotate(45deg);
}

.burger_active:after {
  transform: translateY(0) rotate(-45deg);
}
