@import 'src/styles/scss/mixins';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 168px;
  padding: 5px 30px;
  border-radius: 82px;
  color: $text-color;
  font-family: 'Proxima Nova';
  background-color: $button-primary-color;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 112.5%;
  text-decoration: none;
  border: none;
  cursor: pointer;
  // @include maxTablet {
  //   font-size: 14px;
  // }
  // @include minTablet {
  //   font-size: 10px;
  // }
  transition: all ease-in-out 0.2s;
  &:hover,
  &:active {
    background-color: $button-primary-active-color;
  }

  &:disabled {
    background-color: $button-primary-disabled-color;
  }
  &_primary {
    background-color: $button-primary-color;
    &:hover,
    &:active {
      background-color: $button-primary-active-color;
    }

    &:disabled {
      background-color: $button-primary-disabled-color;
    }
  }
  &_secondary {
    background-color: $button-secondary-color;
    &:hover,
    &:active {
      background-color: $button-secondary-active-color;
    }

    &:disabled {
      background-color: $button-secondary-disabled-color;
    }
  }
  &_third {
    background-color: $button-third-color;
    &:hover,
    &:active {
      background-color: $button-third-active-color;
    }

    &:disabled {
      background-color: $button-third-disabled-color;
    }
  }
  @include tablet {
    font-size: 16px;
    border-radius: 82px;
    min-width: 143px;
  }
  &Wrapper {
    // display: flex;
    height: 60px;

    // min-width: 148px;
    // justify-content: center;
    @include tablet {
      height: 50px;
    }
  }
}
