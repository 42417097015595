@import 'src/styles/scss/variables';
@import 'src/styles/scss/mixins';

.titleWrapper {
  max-width: 1440px;
  margin: auto;
  padding-left: 112px;

  @include tablet {
    padding: 0 18px 0 18px;
  }
}

.titleSection {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;

  &Content {
    padding-top: 147px;
    @include tablet {
      padding-top: 50px !important;
    }

    text-align: start;
    @include headings {
      font-size: 94px;
      font-weight: 700;
      background: linear-gradient(
        90.28deg,
        #d416b9 8%,
        #9b51e0 31.51%,
        #7130fb 59.06%,
        #7e31fb 81.18%,
        #8a32f9 92.49%,
        #7f35f8 97.44%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      @include tablet {
        font-size: 64px;
      }

      @include tablet {
        font-size: 34px;
      }
    }
    p,
    li {
      font-size: 22px;
      font-weight: 400;
      line-height: 30.8px;
      color: $secondary-text-color;
      max-width: 598px;

      @include tablet {
        font-size: 18px;
      }
    }
  }
  &Buttons {
    padding-bottom: 198px;
    margin-top: 53px;
    display: flex;
    gap: 43px;
    button {
      margin-left: 40px;
      &:first-child {
        margin-left: 0;
      }
      @include medium-phone {
        margin-left: 14px;
      }
    }
  }
}
