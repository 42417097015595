@import 'src/styles/scss/mixins';

div.tabsBlock {
  &Tabs {
    display: flex;
    gap: 60px;
    padding-top: 48px;
    padding-bottom: 48px;
    margin: auto;
    max-width: 1440px;

    @include tablet {
      padding-left: 50px;
      padding-right: 50px;
      overflow-y: hidden;
    }

    @include phone {
      padding-right: 18px;
      padding-left: 18px;
    }

    &TabWrapper {
      margin-right: 62px;

      &:last-child {
        margin-right: 0;
      }

      &Tab {
        color: $text-color; // TODO
        position: relative;
        cursor: pointer;
        white-space: nowrap;
        &:after {
          position: absolute;
          content: '';
          left: 0;
          right: 0;
          bottom: -10px;
          width: 0%;
          height: 4px;
          background: #d416b9;
        }
        &:hover {
          &:after {
            width: 100%;
            will-change: transition;
            transition: width 0.4s cubic-bezier(0.19, 1, 0.22, 1);
            transform: translateZ(0);
          }
        }
      }
    }

    .active {
      .tabsBlockTabsTabWrapperTab {
        color: white; // TODO
        position: relative;

        &::after {
          position: absolute;
          content: '';
          left: 0;
          right: 0;
          bottom: -10px;
          width: 100%;
          height: 4px;
          background: #d416b9; // TODO
          will-change: transition;
          transition: width 0.4s cubic-bezier(0.19, 1, 0.22, 1);
          transform: translateZ(0);
        }
      }
    }
    .tabsBlockTabsBannerContent {
    }
  }
}

.anchor {
  text-decoration: none;
}
