@import 'src/styles/scss/mixins';


.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-width: 100vw;
  height: 100vh;
  min-height: 100vh;
  background: linear-gradient(166.89deg, #33364C 3.33%, #222434 28.55%, #25283A 53.5%, #0C0E1B 89.92%);
  &__icon {
    display: block;
    @include rotation-animation($animationRotation) {
      animation-name: $animationRotation;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
  &__text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 96px), -50%);
  }
}