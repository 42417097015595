section {
  .wrapper {
    display: flex;
    max-width: 1440px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    @include phone {
      width: 100%;
    }
  }
  .blockContentImage > img {
    max-width: 100%;
    object-fit: contain;
  }
  .right {
    :global {
      .react-player__preview {
        border-radius: 25px;
      }
    }
    flex-direction: row-reverse;
    > img {
      height: auto;
      width: 50%;
      max-width: 100%;
      @include phone {
        width: auto;
      }
    }
    .sectionImage,
    .sectionBackgroundImage {
      // max-width: 700px;
      max-width: 645px;
      height: auto;
      width: auto;
      // margin-top: 123px;

      @include laptop {
        max-width: 550px;
      }

      @include maxTablet {
        max-width: 400px;
      }

      @include phone {
        max-width: 86.3vw;
        margin-top: 58px;
        // margin-left: auto;
        margin-right: 0;
      }
    }
    .blockContent {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 616px;
      margin-right: 4.4%;

      @include tablet {
        width: 100%;
        // margin-right: 8.7vw;
        margin-right: 0px;
      }
      @include phone {
      }
    }

    .blockContentImage {
      display: flex;
      justify-content: flex-end;
      max-width: 698px;
      // width: 53.61%;
      width: 45.91%;
      height: auto;
      > img {
        max-width: 100%;
      }
      @include laptop {
        width: 100%;
        // width: 29.472vw;
        // height: 22.944vw;
      }

      @include maxTablet {
        // width: 30.472vw;
        // height: 20.944vw;
      }

      @include tablet {
        width: 73.067vw;
        margin: 0 auto;
        height: auto;
        max-width: 100%;
      }

      @include medium-phone {
        width: 86.067vw;
      }
    }
  }

  .videoPlayerIcon {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 135px;
    width: 135px;

    border-radius: 50%;
    background: $text-color;
    opacity: 0.8;

    @include phone {
      height: 21vw;
      width: 21vw;
    }

    img {
      margin-left: 0.694vw;
    }
  }

  .left {
    flex-direction: row;
    @include phone {
      flex-direction: column-reverse;
    }
    :global {
      .react-player__preview {
        border-radius: 25px;
      }
    }
    .sectionImage,
    .sectionBackgroundImage {
      // max-width: 42.3vw;
      // margin-top: 123px;
      max-width: 532px;
      height: auto;
      margin-left: -20px;
      width: 44.3%;
      @include phone {
        margin-left: 0;
        width: 100%;
        max-width: 86.3vw;
        margin-top: 58px;
        margin-bottom: 50px;
      }
    }
    .blockContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 12.7vw;

      @include tablet {
        width: 100%;
        margin-left: 8.7vw;
      }

      @include phone {
        margin-left: 0px;
      }
    }

    .blockContentImage {
      max-width: 698px;
      height: auto;

      @include laptop {
        width: 29.472vw;
        // height: 22.944vw;
      }

      @include maxTablet {
        width: 30.472vw;
        // height: 20.944vw;
      }

      @include tablet {
        width: 73.067vw;
        margin: 0 auto;
        height: auto;
      }

      @include medium-phone {
        width: 86.067vw;
      }
    }
  }

  .left.reverseDirection {
    @include phone {
      flex-direction: column;
    }
  }

  .top {
    flex-direction: column;
  }

  .bottom {
    flex-direction: column-reverse;
  }

  .imgWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .mobilePictureLeft {
    position: absolute;
    z-index: 100;
    left: 20vw;
    top: -6.25vw;
  }

  .mobilePictureRight {
    position: absolute;
    z-index: 100;
    right: 20vw;
    top: -5.278vw;
  }
  .backgroundPictureLeft,
  .backgroundPictureRight {
    height: 48.056vw;
    width: 36.597vw;

    @include phone {
      // width: 262px;
    }
    z-index: -100;
    position: absolute;
  }

  .fullWidthBackround {
    position: absolute;
    left: 0;
    z-index: -100;
    width: 100%;
  }

  .backgroundPictureLeft {
    left: 0;
  }

  .backgroundPictureRight {
    right: 0;
  }

  .content {
    width: 50%;
  }

  .contentMargin {
    // width: 49%;
    // padding-top: 5.3%;
    // width: 44%;
    // width: 38.54%;
    width: 43.64%;
    padding-top: 9.3%;
    @include laptop {
      padding-top: 3.3%;
    }
    @include maxTablet {
      padding-right: 60px;
    }
    @include tablet {
      padding-right: 16px;
    }


    @include phone {
      margin-top: 31px;
      max-width: 300px;
      width: 100%;
      margin-left: 0;
      padding-left: 0;
    }
  }

  .lastSection {
    display: flex;
    justify-content: center;
    text-align: center;

    p {
      margin: auto;
      margin-bottom: 4.583vw;
    }
  }

  .reactPlayer {
    border-radius: 25px;
  }

  .left.fullWidth {
    // padding-right: 112px;
    // margin-left: 0px;
    width: 100%;
    @include phone {
      // padding-right: 0;
      margin-left: 0px;
    }
    .backgroundOverlay {
      padding-right: 0;
      margin-right: 13px;
      width: 41vw;
      max-width: 588px;
      max-height: 686px;
      height: 47.8vw;
      background-size: contain;
      background-position: left;
      background-repeat: no-repeat;
      position: relative;

      @include phone {
        height: 107.467vw;
        width: 69.867vw;
        margin-top: 96px;
        padding-right: 0;
      }

      .device {
        position: absolute;
        right: 0;
        left: auto;
        top: -12%;
        width: 49.4%;
        max-width: 283px;

        @include phone {
          width: 50.867vw;
          top: -17.6%;
          right: -32%;
        }
      }
    }
  }
  .right.fullWidth {
    width: 100%;
    // margin-bottom: 6.25vw;
    &.deviceLogoControl {
      > div.contentMargin {
        padding-top: 11.7%;
      }
      .backgroundOverlay img {
        top: 25%;
        left: -0.5%;
        width: 68%;
        max-width: 400px;
        position: absolute;

        @include phone {
          width: 68%;
          left: 13%;
          top: 26%;
        }
      }
    }
    @include phone {
      // padding-left: 3vw;
      margin-left: 0;
    }
    .contentMargin {
      // width: 36%;
      width: 50%;
      padding-top: 10.2%;
      padding-left: 112px;
      @include maxTablet {
        padding-left: 60px;
      }
      @include tablet {
        padding-left: 16px;
      }
      @include phone {
        width: 100%;
      }
    }

    .deviceLogoControl {
      .backgroundOverlay {
        width: 45%;

        @include phone {
          width: 100%;
        }
      }
    }
    .backgroundOverlay {
      padding-left: 0;
      margin-left: 13px;
      width: 42.3vw;
      max-width: 592px;
      max-height: 686px;
      height: 47.8vw;
      background-size: contain;
      background-position: right;
      background-repeat: no-repeat;
      position: relative;

      @include phone {
        height: 107.467vw;
        width: 69.867vw;
        margin-top: 37px;
        margin-left: auto;
        margin-right: 0;
      }

      .device {
        position: absolute;
        left: 0;
        top: -11.2%;
        width: 49.4%;
        max-width: 283px;

        @include phone {
          width: 45.867vw;
        }
      }

      .chatControl {
        left: -0.6%;
        top: 2.5%;
        width: 88.7%;
        max-width: 416px;

        @include phone {
          width: 100%;
          top: -5%;
          left: -18%;
        }
      }

      .deviceLogoControl {
        .backgroundOverlay {
          left: -0.6%;
          top: 2.5%;
          width: 88.7%;
          max-width: 416px;

          @include phone {
            width: 100%;
            top: -5%;
            left: -18%;
          }
        }
      }
    }
  }

  .none.fullWidth {
    justify-content: center;
    // align-items: center;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    // height: 39.167vw;
    width: 100%;
    max-width: 1440px;
    min-height: 564px;
    background-repeat: no-repeat;
    .content {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    @include phone {
      min-height: 70vw;
    }
  }

  .headerBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  .helpSection {
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0;
    text-align: center;
    padding-top: 6.9%;
    @include desktop {
      width: 36.6vw;
    }

    @include phone {
      width: 100%;
      min-height: 476px;
      padding-left: 0;
      padding-top: 14.8%;
      > button,
      > a {
        width: 142px;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include desktop {
        font-size: 50px;
        line-height: 112%;
      }
      @include phone {
        font-weight: 700;
        font-size: 30px;
        max-width: 232px;
        margin-bottom: 25px;
      }
    }
    p,
    li {
      @include desktop {
        font-weight: 400;
        font-size: 22px;
        line-height: 140%;
        margin-bottom: 4.931vw;
      }
      @include phone {
        font-size: 16px;
        max-width: 286px;
        margin-bottom: 71px;
      }
    }
  }

  .mobileContent {
    @include phone {
      // ing: 0 3vw;
    }
  }

  .videoWrapper {
    height: 0;
    position: relative;
    padding-bottom: 38.45%;
    width: 52.872vw;
    max-width: 698px;
    margin-left: 16px;
    @include phone {
      margin-top: 40px;
      padding-bottom: 63.25%;
      margin-bottom: -26px;
      margin-left: 0;
      width: 100%;
    }
  }

  .groupLogo {
    max-width: 738px;
    margin-left: 0;
    @include laptop {
      max-width: 40vw;
    }

    @include phone {
      max-width: 365px;
      margin-top: 56px;
      width: 100%;
      margin-left: auto;
      padding-left: 15vw;
    }
  }

  .isSimilarToBlock {
    // margin-left: 40px;
    margin-left: 112px;
    margin-top: 59px;
    @include maxTablet {
      margin-top: 0;
    }
    @include phone {
      margin-top: 0px;
      min-width: 300px;
      margin-left: 0px;
      padding-left: 0 !important;
    }
    p {
      margin-bottom: 30px;
      line-height: 1.4;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .right.defaultContent {
    padding: 0 112px 68px 112px;
    align-items: center;

    @include maxTablet {
      justify-content: space-between;
    }

    @include tablet {
      align-items: flex-start;
      padding: 0 5.764vw 68px 7.778vw;
    }

    @include phone {
      padding-left: 4.267vw;
      padding-right: 4.267vw;
    }

    .blockContent {
      width: 34.1vw;
      margin-right: 7.7vw;

      @include tablet {
        width: 100%;
        margin-right: 0;
      }

      p,
      li {
        font-size: 22px;
        & + p {
          margin-top: 30px;
        }
        @include tablet {
          font-size: 18px;
        }

        @include medium-phone {
          font-size: 16px;
        }
      }
    }
    .blockContentImage {
      width: 36.7vw;
      height: 100%;

      @include maxTablet {
        width: 30vw;
      }

      @include tablet {
        width: 86.067vw;
      }

      @include medium-phone {
        width: 86.067vw;
      }
    }
  }

  .left.defaultContent {
    padding: 0 112px 68px 112px;
    align-items: center;

    @include maxTablet {
      justify-content: space-between;
    }

    @include tablet {
      padding: 0 5.764vw 68px 7.778vw;
      padding-bottom: 68px;
      align-items: flex-start;
    }

    @include phone {
      padding-left: 4.267vw;
      padding-right: 4.267vw;
    }

    .blockContent {
      width: 34.1vw;
      margin-left: 7.7vw;

      @include tablet {
        width: 100%;
        margin-left: 0;
      }

      p,
      li {
        font-size: 22px;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }

        @include tablet {
          font-size: 18px;
        }

        @include medium-phone {
          font-size: 16px;
        }
      }
    }
    .blockContentImage {
      width: 36.7vw;
      height: 100%;

      @include maxTablet {
        width: 30vw;
      }

      @include tablet {
        width: 86.067vw;
      }

      @include medium-phone {
        width: 86.067vw;
      }
    }
  }

  .hugeLogo {
    max-width: 27.778vw;
    width: 100%;
    margin: 0 auto !important;
  }

  div.hugeLogoBottomAlign {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    // min-height: 370px;
    width: 53.61%;

    .blockContentImage {
      width: 100%;
      max-width: 400px;
    }

    @include laptop {
      width: 100%;
    }

    @include phone {
      max-width: 227px;

      margin: 0 auto;
    }
  }

  .block.hugeLogoContent {
    padding-bottom: 17px;
  }
}

.section {
  padding-bottom: 120px;
  background-repeat: no-repeat;
  background-position: bottom;
  @include tablet {
    padding-bottom: 96px;
  }
  @include phone {
    padding-bottom: 0;
  }
  &:first-child {
    .wrapper {
      .sectionImage {
        // margin-top: 60px;
        // max-width: 780px;
        // margin-right: -86px;
        // width: 63%;
        margin-top: 92px;
        max-width: 609px;
        width: 50.1%;
        z-index: 1;
        // max-width: 793px;
        // margin-right: -87px;
        // width: 65.3%;
        @include maxTablet {
          // margin-right: -60px;
        }
        @include tablet {
          // margin-right: -16px;
        }
        @include phone {
          max-width: 100%;
          width: auto;
          // margin-right: 0;

          width: 83.6%;
          margin: 54px auto 82px;
        }
      }
      .sectionBackgroundImage {
        position: absolute;
        max-width: 793px;
        width: 55.1%;
        right: 24px;
        top: 92px;
        z-index: 0;

        @include maxTablet {
          max-width: 546px;
        }
        @include phone {
          bottom: 50px;
          top: auto;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
        }
      }
    }
    @include headings {
      font-size: 64px;
      margin-bottom: 26px;
      margin-top: 20px;
      @include maxTablet {
        font-size: 32px;
      }
      @include minTablet {
        font-size: 20px;
      }

      @include phone {
        font-size: 30px;
        margin-bottom: 10px;
        margin-top: 37px;
      }
    }
    .contentMargin {
      @include phone {
        margin-top: 24px;
        max-width: 293px;
      }
    }
  }
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  div.smallContent {
    width: 100%;
    max-width: 470px;
    padding-top: 0;

    @include tablet {
      max-width: 375px;
    }
  }
  @include headings {
    color: $text-color; // Apply after gradient background;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 112%;
    margin-bottom: 26px;
    margin-top: 20px;

    @include maxTablet {
      font-size: 32px;
    }
    @include minTablet {
      font-size: 20px;
    }

    @include phone {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 70px;
    max-width: 548px;
    line-height: 1.4;
    color: $secondary-text-color;
    @include tablet {
      font-size: 11px;
    }
    @include minTablet {
      font-size: 9px;
    }
    @include phone {
      font-size: 16px;
      line-height: 140%;
      margin-top: 0px;
      max-width: 350px;
      width: 100%;
      margin-bottom: 30px;
    }
  }

  display: flex;
  flex-direction: row;

  .right {
    flex-direction: row-reverse;

    @include phone {
      flex-direction: column-reverse;
      //padding-bottom: 65px;
    }
  }

  .contentLeft {
    // margin-left: 15px;
    @include phone {
      padding-left: 16px;
    }
  }

  .contentRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .buttons {
    display: flex;
    gap: 30px;
    button,
    a {
      @include phone {
        max-width: 180px;
      }
      // margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
    @include tablet {
      display: flex;
      flex-direction: column;
      gap: 20px;
      button,
      a {
        margin-right: 0;
      }
    }
  }
}

.blockWrapper {
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  &:last-child {
    .block {
      min-height: 564px;
      @include phone {
        min-height: 476px;
      }
    }
  }
  &:first-child {
    .block {
      @include laptop {
        flex-direction: column;
        gap: 43px;
        align-items: flex-start;
        padding-bottom: 57px;
      }
      @include phone {
        padding-bottom: 37px;
      }
      .blockContent.hugeLogoContent {
        h2 {
          margin-bottom: 15px;
          margin-top: 37px;
        }
      }
    }
    .right {
      .blockContentImage {
        max-height: 445px;
      }
      .blockContent {
        max-width: 600px;
        margin-right: 0;

        // width: 41.67%;
        width: 57.67%;
        margin-right: 4.72%;

        @include laptop {
          width: 100%;
          margin-right: 0;
        }
      }
    }
    @include headings {
      font-size: 64px;
      margin-bottom: 26px;
      margin-top: 20px;
      @include maxTablet {
        font-size: 72px;
      }
      @include tablet {
        font-size: 58px;
      }
      //@include minTablet {
      //  font-size: 20px;
      //}

      @include phone {
        font-size: 38px;
        margin-bottom: 10px;
      }
    }
  }
  .left {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  .right {
    flex-direction: row;
    justify-content: flex-start;
  }
  @include headings {
    color: $text-color;
    font-weight: 700;
    font-size: 50px;
    line-height: 112%;
    margin-top: 0;
    margin-bottom: 30px;
    // @include maxTablet {
    //   font-size: 50px;
    // }

    @include tablet {
      font-size: 48px;
    }

    @include phone {
      font-size: 30px;
      margin: 0 0 25px;
    }
  }
  .block {
    display: flex;
    padding: 54px 112px 55px 112px;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    max-width: 1440px;
    margin: 0 auto;
    align-items: flex-start;

    @include maxTablet {
      padding-left: 60px;
      padding-right: 60px;
    }
    @include tablet {
      flex-direction: column;
      gap: 43px;
      align-items: flex-start;
      padding-bottom: 57px;
      padding-left: 16px;
      padding-right: 16px;
    }

    @include phone {
      padding-left: 16px;
      // padding-right: 4.267vw;
    }
    &.blockAlignBottom {
      align-items: flex-end;
      padding-bottom: 0;
      @include laptop {
        align-items: flex-start;
      }
    }
    .blockAlignLeft {
      justify-content: flex-start;
    }
    &Content {
      margin: 20px 0 0;
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      li {
        margin-bottom: 53px;
        @include phone {
          margin-bottom: 24px;
        }
      }

      p,
      li {
        color: $secondary-text-color; // Todo: change colors
        font-size: 22px;
        font-weight: 400;
        line-height: 140%;
        margin: 0;

        @include maxTablet {
          font-size: 20px;
        }

        @include tablet {
          font-size: 18px;
        }

        @include medium-phone {
          font-size: 16px;
        }
      }
      > button {
        margin-top: 30px;
      }
    }
    &.center {
      justify-content: center;
      align-items: center;
      //padding: 20px 0 40px 0;
      padding: 55px 0 40px 0;
      @include phone {
        justify-content: flex-start;
      }
      .blockContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &Content {
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p,
          li {
            text-align: center;

            @include medium-phone {
              font-size: 24px;
            }
          }
          @include headings {
            font-size: 50px;
            margin-bottom: 1.8vw;

            @include tablet {
              font-size: 34px;
            }

            @include phone {
              font-size: 24px;
              max-width: 312px;
            }
          }

          p,
          li {
            margin-bottom: 40px;
            max-width: 577px;
            line-height: 140%;
            text-align: center;

            @include phone {
              font-size: 16px;
              max-width: 312px;
            }
          }
        }
        button {
          margin-top: 0;
        }
      }
    }
    div.smallContent {
      width: 100%;
      max-width: 470px;
      padding-top: 0;
      p {
        max-width: 336px;
      }
      @include headings {
        color: $text-color;
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 112%;
        // font-size: 50px;
        // margin-bottom: 2.6vw;

        @include tablet {
          font-size: 24px;
        }
      }
      @include tablet {
        max-width: 375px;
      }
    }
    .center {
      justify-content: center;
      align-items: center;
    }
  }

  .block.blockWithHugeLogo {
    padding-bottom: 0 !important;
    margin-bottom: 87px;
    @include phone {
      margin-bottom: 59px;
      padding-top: 0;
    }
  }
  .buttons {
    display: flex;
    margin-top: 3.689vw;
    margin-bottom: 64px;
    gap: 30px;
    button,
    a {
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }

    @include tablet {
      margin-top: 26px;
      margin-bottom: 0;
      flex-direction: column;
      gap: 30px;
      button,
      a {
        margin-right: 0;
      }
    }
  }
}
