//resolutions size
$ultra-4k: 4096px;
$ultra-hd: 3840px;
$quad-4k: 3200px;
$quad-hd: 2560px;
$full-hd: 1920px;
$hd-plus: 1600px;
$macbook-fifteen: 1445px;
$macbook-thirteen: 1440px;
$desktop: 1366px;
$below-desktop: 1330px;
$macbook-twelve: 1280px;
$below-macbook-twelve: 1150px;
$max-tablet-size: 1024px;
$below-max-tablet-size: 1023px;
$between-max-tablet-size-tablet-size: 816px;
$default-tablet-size: 768px;
$min-tablet-size: 683px;
$max-mobile-size: 568px;
$medium-mobile-size: 480px;
$small-mobile-size: 375px;

$primary-color: #272a3f;
$secondary-color: #d416b9;
$text-color: #fff;
$secondary-text-color: #c5c8d1;
$third-text-color: #6e758b;
$background-color: #272a3f;
$secondary-background-color: #d416b9;
$third-background-color: #494c62;
$fourth-background-color: #5c6079;
$white-color: #fff;
$button-primary-color: #d416b9;
$button-primary-active-color: #be12a6;
$button-primary-disabled-color: #e4d2e2;
$button-secondary-color: #634f6e;
$button-secondary-active-color: #4e385a;
$button-secondary-disabled-color: #bfb2bd;
$button-third-color: #272a3f;
$button-third-active-color: #272a3f9a;
$button-third-disabled-color: #827981;
$light-gray: #f5f5f5;

// icon
$arrow-down-url: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEwIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOS41MzAzMyAwLjk2OTY3QzkuODIzMjIgMS4yNjI1NiA5LjgyMzIyIDEuNzM3NDQgOS41MzAzMyAyLjAzMDMzTDUuNTMwMzMgNi4wMzAzM0M1LjIzNzQ0IDYuMzIzMjIgNC43NjI1NiA2LjMyMzIyIDQuNDY5NjcgNi4wMzAzM0wwLjQ2OTY3IDIuMDMwMzNDMC4xNzY3NzcgMS43Mzc0NCAwLjE3Njc3NyAxLjI2MjU2IDAuNDY5NjcgMC45Njk2N0MwLjc2MjU2MyAwLjY3Njc3NyAxLjIzNzQ0IDAuNjc2Nzc3IDEuNTMwMzMgMC45Njk2N0w1IDQuNDM5MzRMOC40Njk2NyAwLjk2OTY3QzguNzYyNTYgMC42NzY3NzcgOS4yMzc0NCAwLjY3Njc3NyA5LjUzMDMzIDAuOTY5NjdaIiBmaWxsPSIjRjJGMkYyIi8+Cjwvc3ZnPgo=');
// $pink-arrow-right-url: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTguNDcgMS41M0EuNzUuNzUgMCAwIDEgOS41My40N2w0IDRhLjc1Ljc1IDAgMCAxIDAgMS4wNmwtNCA0YS43NS43NSAwIDAgMS0xLjA2LTEuMDZsMi43Mi0yLjcySDEuNWEuNzUuNzUgMCAwIDEgMC0xLjVoOS42OUw4LjQ3IDEuNTNaIiBmaWxsPSIjRDQxNkI5Ii8+PC9zdmc+');

// animation
$animationRotation: rotation;
