@import 'src/styles/scss/mixins';

.content {
  display: flex;
  flex-direction: column;
  &__title {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 120%;
    color: $text-color;
    padding: 0;
    margin: 77px 0 35px 4px;
    letter-spacing: 0.3px;
    @include phone {
      margin: 77px 0 35px 0;
    }
  }
  &__text {
    letter-spacing: 0.2px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: $text-color;
    a {
      color: $secondary-color;
      text-decoration: none;
    }
    p {
      display: inline-block;
      padding: 0;
      // margin: 0 0 10px;
      margin: 0 0 26px 0px;
      line-height: 1.41;
      letter-spacing: 0.16px;
    }
    & > p {
      &:first-child {
        margin: 0 0 26px;
      }
    }
    ol {
      list-style: none;
      // list-style-position: outside;
    }
    & > ol {
      padding: 0 0 0 0px;
      // list-style-position: inside;
      // padding: 0 0 0 10px;
      margin: 0;
      // margin: 27px 0;
      li {
        // &::marker {
        //   content: counters(list-item, '.') ;
        //   letter-spacing: 0.4px;
        // }
      }
      > li {
        > p {
          // margin: 26px -9px 0 -9px;
          margin: 0 -5.9px 26px 0px;
          &:first-child {
            margin: 0px 11px 25.3px 0px;
            display: block;
            text-indent: 0;
          }
        }
      }
      ol {
        padding: 0 0 0 0px;
        // margin: 0;
        list-style-position: inside;
        // padding: 0 0 0 16px;
        > li {
          > p {
            // margin: 26px -9px 0 -9px;
            margin: 0.7px -6px 25.6px 0;
            &:first-child {
              margin: 0.7px -6px 25.6px 0;
              text-indent: 0;
            }
          }
        }
      }
    }
  }
}
