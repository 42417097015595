@import 'src/styles/scss/mixins';

.typography {
  display: flex;
  flex-direction: column;
  padding-bottom: 93px;
  @include tablet {
    padding-bottom: 48px;
  }
  @include phone {
    padding-bottom: 0;
  }
  &Content {
    @include headings {
      color: $white-color;
      max-width: 594px;
      font-size: 34px;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: 34px;

      @include tablet {
        font-size: 24px;
        max-width: 312px;
        margin-bottom: 16px;
      }
    }
    p,
    li {
      color: $secondary-text-color;
      font-size: 22px;
      max-width: 902px;
      line-height: 140%;

      @include tablet {
        font-size: 16px;
        max-width: 312px;
      }
    }
    p {
      margin-bottom: 0;
      & + & {
        margin-top: 65px;
        @include phone {
          margin-bottom: 0;
        }
      }
    }
  }
}
.homeTitle {
  @include headings {
    color: $white-color;
    max-width: 594px;
    font-size: 50px;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 34px;

    @include maxTablet {
      font-size: 32px;
      max-width: 312px;
      margin-bottom: 16px;
    }
  }
  p {
    @include tablet {
      font-size: 11px;
    }
    @include minTablet {
      font-size: 9px;
    }
    @include phone {
      font-size: 16px;
    }
  }
}

section.productsTitle {
  margin-bottom: 64px;

  @include phone {
    margin-bottom: 48px;
  }
}
