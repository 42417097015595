@import 'src/styles/scss/mixins';

.tableOfContent {
  display: flex;
  flex-direction: column;
  padding: 146px 0px 0 14px;
  @include phone {
    padding-top: 20px;
  }

  &__title {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    text-transform: uppercase;
    color: $text-color;
    padding: 0;
    margin: 0 0 33px;
    letter-spacing: 0.2px;
  }
  &__list {
    padding: 0 0 0 13px;
    margin: 0 0 0 2px;
  }
  &__item {
    padding: 0;
    margin: 0 3px 0 0;
    color: $text-color;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.2px;
    &::marker {
      content: counters(list-item, '.') ;
      letter-spacing: 0px;
    }
  }
  &__link {
    display: flex;
    width: fit-content;
    padding: 0;
    margin: 0 0 9.7px 10px;
    text-indent: -10px;
    color: $text-color;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border-bottom-color 0.2s ease-in-out;
    &:hover, &:active {
      border-bottom-color: $secondary-color;
    }
  }
}
