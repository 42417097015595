@import 'src/styles/scss/mixins';

.linkWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.arrowDown {
  position: absolute;
  top: 28px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.nav {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;

  &__list {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0 11px;
    @include maxTablet {
      width: 100%;
      padding: 0;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__item {
    > p {
      &::after {
        display: block;
        background: $arrow-down-url;
        position: absolute;
        top: auto;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
        height: 5.5px;
        width: 9.5px;
        min-width: 9.5px;
        content: '';
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        @include maxTablet {
          content: none;
        }
      }
    }
    & {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 112.5%;
      color: $text-color;
      margin: 0 30px;
      height: 60px;
      cursor: pointer;
      text-decoration: none;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &_active {
        .arrowDown {
          opacity: 1;
        }
        > p {
          &::after {
            opacity: 1;
          }
        }
        @include maxTablet {
          > p {
            color: $secondary-color;
          }
          > svg {
            transform: rotate(90deg);
            path {
              fill: $secondary-color;
            }
          }
        }
      }
      @include laptop {
        font-size: 16px;
        margin: 0 10px;
      }
      @include maxTablet {
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 24px;
        line-height: 112.5%;
        margin: 0 0 17px;
        width: 100%;
        height: auto;
        position: relative;
        padding: 0 4.2vw;

        > svg {
          position: absolute;
          right: 9.3vw;
          top: 21px;
          transition: all 0.2s ease-in-out;
        }
        &::after {
          display: none;
        }
      }
    }
    > a {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 112.5%;
      color: $text-color;

      cursor: pointer;
      text-decoration: none;
      position: relative;

      @include maxTablet {
        font-size: 16px;
      }
      @include maxTablet {
        font-weight: 400;
        font-size: 24px;
        line-height: 112.5%;
        &::after {
          display: none;
        }
      }
    }

    > a,
    > p {
      font-weight: 400;
      font-size: 18px;
      line-height: 112.5%;
      @include laptop {
        margin: 0 10px;
      }
      @include maxTablet {
        margin: 0;
        padding: 17px 10px 16px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        transition: all ease-in-out 0.2s;
        font-weight: 400;
        font-size: 24px;
        line-height: 112.5%;
      }
    }
  }
}
