@import 'src/styles/scss/variables';
@import 'src/styles/scss/mixins';

.linkItem {
  display: flex;
  text-decoration: none;
  align-items: baseline;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  span {
    color: $text-color;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 112.5%;
  }
  &:hover {
    .linkArrow {
    }
    .linkArrowImage {
      transform: translateX(0);
    }
  }
}

.linkArrow {
  margin-left: 25px;
  display: block;
  position: relative;
  overflow: hidden;
  > object {
    cursor: pointer;
  }
  .linkArrowImage {
    transition: transform 0.3s ease-in-out;
    transform: translateX(-9px);
  }

  @include big-phone {
    margin-left: 15px;
  }
}
