@import 'src/styles/scss/mixins';

@mixin banner {
  margin: 0 -112px;
  padding: 110px 112px 105px;
  display: flex;
  align-items: center;
}

.bannerWrapper {
  max-width: 100%;
  display: flex;
  flex-direction: column;

  &Banner {
    padding-top: 110px;
    padding-bottom: 105px;
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      user-select: none;
      pointer-events: none;
      user-select: none;
      pointer-events: none;
      max-width: 240px;
      max-height: 85px;
      @include maxTablet {
        // min-width: 150px;
        max-width: 150px;
      }

      @include tablet {
        // min-width: 125px;
        max-width: 125px;
      }
    }
    :global(.swiper-container) {
      width: 100%;
      margin: 0;
    }
    :global(.swiper-slide) {
      width: auto;
      min-height: 100%;
    }
    .slideWrapper {
      min-height: 85px;
      max-height: 85px;
      max-width: 240px;
      display: flex;
      align-items: center;
    }

    .sliderSync {
      img {
        min-height: 200px;
        max-height: 200px;
        max-width: 200px;
      }
      min-height: 200px;
      max-height: 200px;

      @include phone {
        img {
          min-height: 108px;
          max-height: 108px;
          max-width: 108px;
        }
        min-height: 108px;
        max-height: 108px;
      }
    }
  }
  hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid #494c62;
    margin-bottom: 60px;
    margin-top: 77px;
    width: 100%;
  }
  .withLine {
    margin-top: 32px;
    margin-bottom: 0;
  }
}

.bannerContentHome {
  & > div {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    @include phone {
      margin-bottom: 63px;
    }
  }

  max-width: 1440px;
  margin: 0 auto;
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 46px;
  justify-content: center;
  padding-bottom: 0;
  padding-top: 84px;
  > p {
    max-width: 830px;
  }
  button {
    // min-width: 170px;
  }
  @include phone {
    padding-top: 45px;
    gap: 30px;
    button {
      margin-bottom: 24px;
      min-width: 140px;
    }
  }
  p {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    color: $text-color;
    text-align: center;
    @include maxTablet {
      font-size: 30px;
    }

    @include minTablet {
      font-size: 22px;
    }

    @include big-phone {
      font-weight: 700;
      font-size: 30px;
      line-height: 112%;

      max-width: 320px;
      width: 100%;
    }
  }

  &ImageDesktop {
    width: 55.833vw;
    max-width: 884px;
  }
}

.simpleBanner {
  padding: 73px 112px 66px;

  margin: 0 auto;
  max-width: 1440px;

  > div {
    margin: 0 auto;

    @include tablet {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include tablet {
    padding: 2.639vw 50px 7.917vw 50px;
  }

  @include phone {
    padding: 60px 4.267vw 60px;
  }

  p {
    &:only-child {
      margin: 0;
    }
    max-width: 705px;
    font-weight: 700;
    font-size: 34px;
    line-height: 140%;
    color: $text-color;

    @include phone {
      font-size: 24px;
    }
  }
}
