@import 'src/styles/scss/mixins';
@import 'src/styles/scss/variables';

footer.footer {
  * {
    padding: 0;
    margin: 0;
  }
  max-width: 1440px;
  margin: 0 auto;
  padding-bottom: 37px;
  padding-top: 97px;
  @include tablet {
    padding-top: 43px;
  }

  ul {
    list-style: none;
  }
  .menuItemMobile {
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #6e758b;
    :global {
      .logo {
        margin-bottom: 30px;
      }
    }
    .footerTitle {
      margin-bottom: 26px;

      @include tablet {
        font-size: 16px;
      }
    }
    .footerSubtitle {
      margin-bottom: 33px;

      @include tablet {
        font-size: 16px;
      }
    }
  }

  .menu {
    display: flex;
    gap: 2.083vw;
    @include tablet {
      flex-wrap: wrap;
      // justify-content: center;
      // gap-row: 13px;
    }

    &Item {
      display: flex;
      flex-direction: column;
      width: 25%;
      @include tablet {
        width: 48.8%;
      }
      &:first-child {
        p {
          line-height: 1.4;
          max-width: 204px;
        }
      }

      &Title {
        margin-bottom: 60px;
        font-weight: 700;
        font-size: 20px;
        color: #ffffff;
        @include tablet {
          margin-bottom: 22px;
          font-size: 16px;
        }
      }
      :global {
        .logo {
          margin: -34px 0 0 3px;
          @media (max-width: 576px) {
            margin-bottom: 16px;
          }
        }
      }

      img {
        width: 145.6px;
        margin-bottom: 25px;
      }

      .quoteWrapper {
        position: relative;
      }

      .quoteImage {
        position: absolute;
        max-width: 68px;
        max-height: 68px;
        top: -25px;
      }

      .footerTitle,
      .footerSubtitle {
        margin-bottom: 25px;
        font-weight: 400;
        font-size: 20px;
        color: #6e758b;
        @include tablet {
          font-size: 16px;
        }
      }
      .footerSubtitle {
        margin-bottom: 0;
      }

      ul {
        li {
          margin-bottom: 25px;
          font-weight: 400;
          font-size: 20px;
          color: #6e758b;
          cursor: pointer;
          transition: ease 0.2s;
          &:hover {
            color: $secondary-text-color;
          }
          @include tablet {
            font-size: 16px;
          }
          a {
            font-weight: 400;
            font-size: 20px;
            color: #6e758b;
            text-decoration: none;
            transition: ease 0.2s;
            &:hover {
              color: $secondary-text-color;
            }
            @include tablet {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid #494c62;
    margin-bottom: 60px;
    margin-top: 29px;
  }
  .copyRight {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 20px;
    color: #6e758b;
    @include tablet {
      font-size: 16px;
    }
  }
}
.active {
  a {
    color: $secondary-text-color !important;
  }
}
