@import 'src/styles/scss/mixins';

.nav {
  &__container {
    position: absolute;
    top: calc(100% + 30px);
    left: -39px;
    z-index: 11;
    display: flex;
    flex-direction: column;
    // width: fit-content;
    max-width: 349px;
    margin: 0 auto;
    background-color: #494c62;
    border-radius: 27px;
    transition: all ease-in-out 0.4s;
    opacity: 0;
    &::before {
      position: absolute;
      top: -50px;
      bottom: 0;
      left: 20px;
      display: block;
      height: 50px;
      width: 120px;
      min-width: 9.5px;
      content: '';
      opacity: 0;
    }
    @include maxTablet {
      position: relative;
      top: 0;
      left: 0;
      background-color: transparent;
      max-width: unset;
      width: 100%;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 112.5%;
    color: $text-color;
  }
  &__description {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: $text-color;
  }
  &__button {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 112.5%;
    color: $text-color;

    &:hover,
    &:active {
      &::after {
        opacity: 1;
      }
    }
  }
  &__col {
    padding: 32px 0px;
    &:first-child {
      padding-left: 111px;
      border-right: 1px solid #494c62;
      max-width: 409px;
      width: 28.4%;
      padding-right: 12px;
      @include maxTablet {
        padding: 0;
        width: 100vw !important;
        min-width: 100vw !important;
        max-width: 100vw !important;
      }
    }
    &:nth-child(2) {
      max-width: 309px;
      border-right: 1px solid #494c62;
      width: 21.46%;
      padding-left: 14px;
      padding-right: 12px;
      @include maxTablet {
        width: 100vw !important;
        min-width: 100vw !important;
        max-width: 100vw !important;
        padding: 0;
      }
    }
    &:nth-child(3) {
      position: relative;
      padding-right: 112px;
      padding-left: 42px;
      flex-grow: 1;
      background-color: $third-background-color;
      width: 50.14%;
      & > button {
        background: none;
        border: none;
        position: absolute;
        right: 112px;
        top: 0;
        cursor: pointer;
        svg {
          path {
            transition: stroke 0.3s ease-in-out;
          }
        }
        &:hover {
          svg path {
            stroke: $secondary-text-color;
          }
        }
      }

      @include maxTablet {
        width: 100vw !important;
        min-width: 100vw !important;
        max-width: 100vw !important;
        padding: 0;
      }
    }
  }
  &__sub {
    &Title {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 112.5%;
      margin: 0 0 0 12px;

      text-transform: uppercase;
      color: $text-color;
    }
    &List {
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;
      margin: 31px 0 38px;
      @include maxTablet {
        margin: 3px 0 0px;
      }
    }
    &Item {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 112.5%;
      color: $text-color;
      margin: 0 0 6px;
      // max-width: 282px;
      cursor: pointer;
      @include maxTablet {
        margin: 0 0 19px;
      }
      > a,
      > p {
        display: flex;
        align-items: center;
        padding: 12px 40px 12px 31.6px;
        margin: 0;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 112.5%;
        color: $secondary-text-color;
        text-decoration: none;
        width: max-content;
        min-width: 100%;
        max-width: 100%;
        transition: all 0.2s ease-in-out;
        &:hover,
        &:active {
          background-color: $fourth-background-color;
          color: $text-color;
        }
        @include maxTablet {
          color: $text-color;
          font-weight: 400;
          font-size: 24px;
          line-height: 112.5%;
          padding: 12px 54px 12px 31.6px;
          &:hover,
          &:active {
            background-color: transparent;
            color: $secondary-color;
          }
        }
      }
      // &:hover {
      //   background-color: $third-background-color;
      // }
      &_hovered {
        background-color: $third-background-color;
      }
    }
    &Item.hovered {
      background-color: $third-background-color;
    }
    &Sub {
      &List {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;
        margin: 51px 0 0;
      }
      &Item {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 112.5%;
        margin: 0 0 4px;
        max-width: 282px;
        color: $text-color;
        > a {
          display: flex;
          align-items: center;
          padding: 11px 10px 15px;
          margin: 0;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 112.5%;
          color: $text-color;
          text-decoration: none;
        }
        cursor: pointer;
        // &:hover {
        //   background-color: $third-background-color;
        // }
        &_hovered {
          background-color: $third-background-color;
        }
      }
    }
  }
}
