@import 'src/styles/scss/mixins';
.itemWrapper {
  width: 27.63%;
  @include phone {
    width: 100%;
  }
  .titleItem {
    margin-top: 2.083vw;
    color: $text-color;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 112%;

    @include maxTablet {
      font-size: 15px;
    }
    @include phone {
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
    }
    @include big-phone {
      font-weight: 700;
      font-size: 24px;
      line-height: 112%;
      margin-top: 25px;
      margin-bottom: 0;
      max-width: 326px;
    }
  }
  .linkItem {
    display: flex;
    gap: 1.389vw;
    text-decoration: none;

    h4 {
      color: $text-color;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 112.5%;

      @include maxTablet {
        font-size: 12px;
      }
      @include minTablet {
        font-size: 10px;
      }
      @include big-phone {
        font-weight: 700;
        font-size: 18px;
        line-height: 112.5%;
      }
    }
  }
  .textItem {
    color: $secondary-text-color;
    font-weight: 400;
    font-size: 22px;
    line-height: 140%;

    @include maxTablet {
      font-size: 14px;
    }

    @include phone {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
    }

    @include big-phone {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      max-width: 300px;
      margin-bottom: 0;
      width: 100%;
    }
  }
  .linkArrow {
    @include big-phone {
      margin-left: 15px;
    }
  }
  button {
    @include phone {
      margin-top: 20px;
      padding: 0;
    }
  }
}

.center {
  text-align: center;
  width: 27.7%;
  .contentText {
    min-height: auto;
  }
  @include tablet {
    width: 100%;
  }

  p {
    margin-left: auto;
    margin-right: auto;
  }
  @include headings {
    margin-left: auto;
    margin-right: auto;
  }
}

.contentText {
  min-height: 320px;

  @include maxTablet {
    min-height: 170px;
  }
  @include phone {
    min-height: auto;
  }
}
