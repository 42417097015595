@import 'src/styles/scss/mixins';

.richtext {
  &__container {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 10.67%;
    margin-bottom: 179px !important;
    @include maxTablet{
      flex-direction: column-reverse;
      column-gap: 0;
    }
  }
  &__col {
    flex-grow: 1;
    &_left {
      width: 48.6%;
      max-width: 706px;
      @include maxTablet{
        width: 100%;
      }
    }
    &_right {
      width: 26.39%;
      max-width: 380px;
      @include maxTablet{
        width: 100%;
      }
    }
  }
}
